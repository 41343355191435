var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',[_c('div',{staticClass:"top"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('span',{staticClass:"handle"},[_vm._v("人员统计")])]),_c('div',{staticClass:"select-group"},[_c('el-cascader',{staticClass:"input-orgname",attrs:{"placeholder":"请选择所属单位","options":_vm.selectList,"show-all-levels":false,"props":{
          children: 'child',
          label: 'name',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
          empty: false
        },"clearable":""},model:{value:(_vm.organId),callback:function ($$v) {_vm.organId=$$v},expression:"organId"}}),_c('div',{staticClass:"btn",on:{"click":_vm.handle_change}},[_vm._v("搜索")])],1),_c('div',{staticStyle:{"display":"flex","padding-top":"18px"}},[_c('div',{staticClass:"top_left"},[_c('div',{staticClass:"chart-box",staticStyle:{"display":"flex","justify-content":"center","height":"100%"},attrs:{"id":"device-module-box"}},[_c('div',{staticClass:"chart-box2",attrs:{"id":"device-module-box2"}})])]),_c('div',{staticClass:"top_right"},[_c('Table',{staticClass:"task-table",attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource,"tree-props":{ children: 'child', hasChildren: 'hasChildren' }}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }