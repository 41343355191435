<template>
  <Box>
    <div class="top">
      <div style="display: flex; justify-content: center; align-items: center">
        <span class="handle">设备工作状态统计</span>
      </div>
      <div class="select-group">
        <el-cascader
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="organId"
          :options="selectList"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          clearable
        >
        </el-cascader>

        <div class="btn" @click="change">搜索</div>
      </div>
      <div class="flex" style="padding-top: 24px; padding-left: 32px">
        <div class="flex">
          <div id="device-module-box" class="chart-box"></div>
          <div class="chart-value" style="padding-top: 55px">
            <div class="status-list">
              <div class="status-item" v-for="item in topList" :key="item.id">
                <div class="status-item-name">
                  <span
                    class="status-item-name-color"
                    :style="`background:${item.color};`"
                  ></span>
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex" style="padding-left: 80px">
          <div id="device-module-box2" class="chart-box2"></div>
          <div class="chart-value" style="padding-top: 55px">
            <div class="status-list">
              <div class="status-item" v-for="item in rightList" :key="item.id">
                <div class="status-item-name">
                  <span
                    class="status-item-name-color"
                    :style="`background:${item.color};`"
                  ></span>
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
let chart, chart2;
import Box from "@/components/platform/common/box";
import { Chart } from "@antv/g2";
import {mapGetters,mapActions} from "vuex"
import API from "@/api";
export default {
  computed:{
    ...mapGetters(["dbox_data"])
  },
  components: {
    Box,

  },
  data() {
    return {
      selectList:null,
      topList: [
        {
          id: 1,
          title: "在线数量",
          color: "#0D7AF5",
        },
        {
          id: 2,
          title: "离线数量",
          color: "#EE3A75",
        },
      ],
      rightList: [
        {
          id: 1,
          title: "常态任务",
          color: "#0D7AF5",
        },
        {
          id: 2,
          title: "临时任务",
          color: "#E99702",
        },
      ],
      stateList: [
        { type: "在线数量", value: null },
        { type: "离线数量", value: null },
      ],
      implementList: [
        { type: "常态任务", value: null },
        { type: "临时任务", value: null },
      ],
      organId: 1,
    };
  },
  methods: {
    ...mapActions("user",["Dbox"]),
    async init_chart() {
      await this.getlist();
      this.in();
      this.inTwo();
    },
    in() {
      chart = new Chart({
        container: "device-module-box",
        width: 178,
        height: 178,
      });
      chart.annotation().text({
        position: ["50%", "50%"],
//         content: `作业任务
// 状态统计`,
        content: `工作状
态统计`,
        style: {
          fontSize: 20,
          fill: "#CDEDFF",
          textAlign: "center",
        },
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });
      chart.data(this.stateList);
      chart.coordinate("theta", {
        radius: 0.88,
        innerRadius: 0.7,
      });
      chart.legend(false);
      chart
        .interval()
        .adjust("stack")
        .position("value")
        .color("type", ["#0D7AF5", "#EE3A75"]);
      chart.render();
    },
    inTwo() {
      chart2 = new Chart({
        container: "device-module-box2",
        height: 178,
        width: 178,
      });
      chart2.annotation().text({
        position: ["50%", "50%"],
//         content: `作业任务
// 类型统计`,
        content: `执行任务
数统计`,
        style: {
          fontSize: 20,
          fill: "#CDEDFF",
          textAlign: "center",
        },
      });
      chart2.tooltip({
        showTitle: false,
        showMarkers: false,
      });
      chart2.data(this.implementList);
      chart2.coordinate("theta", {
        radius: 0.88,
        innerRadius: 0.7,
      });
      chart2.legend(false);
      chart2
        .interval()
        .adjust("stack")
        .position("value")
        .color("type", ["#0D7AF5", "#E99702"]);
      chart2.render();
    },
    getAllOrganization() {
      // let { data } = await API.AIRWAY.getAllOrganizationBack();
      let data  =  this.dbox_data
      this.selectList = this.getTreeData(data);
      // console.log(this.selectList);
      this.organId = this.selectList[this.selectList.length-1].id
      // console.log(this.dbox_data);
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    async getlist() {
      const { data } = await API.DEVICE.StatusInfo({ organizationId: this.organId });
      this.stateList[0].value = data.device.onlineDevice;
      this.stateList[1].value = data.device.offlineDevice;
      this.implementList[0].value = Number(data.normality.data);
      this.implementList[1].value = Number(data.temping.data);
    },
    async change() {
      await this.getlist();
      chart.changeData(this.stateList);
      chart2.changeData(this.implementList);
    },
  },

  mounted() {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permiss = permissions.map((item) => item.permissionSign);
    if(permiss && permiss.indexOf('hawk:control:equipmentManage:stateStatistics') > -1) {
      this.getAllOrganization()
      this.Dbox()
      this.init_chart();
    }
  },
  
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
}
.handle {
  background: url(~@/assets/platform/device/header.png) no-repeat;
  width: 528px;
  height: 32px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-group {
  padding-top: 16px;
  display: flex;
  ::v-deep .el-cascader {
    box-sizing: border-box;
    width: 176px;
    height: 36px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
    .el-input {
      box-sizing: border-box;
      .el-input__inner {
        height: 36px;
        line-height: 36px;
        background: #03183180;
        border: 1px solid #214884;
        box-sizing: border-box;
        border-radius: 0;
        color: #e0e6f2;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            line-height: 20px;
          }
          .el-select__caret {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.btn {
  width: 82px;
  height: 36px;
  opacity: 0.8;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
.flex {
  display: flex;
}
.chart-box {
  width: 178px;
  height: 178px;
  box-sizing: border-box;
  margin-right: 16px;
  flex-shrink: 0;
  background: center center url(~@/assets/platform/device/workStatus.png)
    no-repeat;
  background-size: calc(100% - 1px) calc(100% - 1px);
  box-sizing: border-box;
}
.chart-box2 {
  width: 178px;
  height: 178px;
  box-sizing: border-box;
  margin-right: 16px;
  flex-shrink: 0;
  background: center center url(~@/assets/platform/device/workStatus.png)
    no-repeat;
  background-size: calc(100% - 1px) calc(100% - 1px);
  box-sizing: border-box;
}
.chart-value {
  flex: 1;
  box-sizing: border-box;
  .status-list {
    display: flex;
    flex-direction: column;
    .status-item {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 10px;
      margin-bottom: 4px;
      &-name {
        font-size: 12px;
        color: #caf2ff;
        font-weight: 400;
        display: flex;
        align-items: center;
        &-color {
          background: #000;
          width: 9px;
          height: 9px;
          border-radius: 9px;
          margin-right: 8px;
        }
      }
      &-value {
        font-size: 12px;
        color: #caf2ff;
        text-align: right;
        font-weight: 400;
      }
    }
  }
}
</style>