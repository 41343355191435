<template>
  <div class="cpt-task-list-search">
    <div style="display: flex; align-items: center">
      <div v-if="routeButton" v-view="{sign:'basicResources:airspace:add'}" @click="$emit('on-edit')" class="linear-btn">绘制空域</div>
      <el-form
        :model="search_form"
        style="margin-left: 24px"
        class="linear-form"
        inline
      >
        <el-form-item label="空域名称:">
          <el-input
            class="padding"
            v-model="search_form.name"
            size="mini"
            placeholder="请输入空域名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="空域类型:">
          <el-select
            v-model="search_form.typeId"
            size="mini"
            clearable
            placeholder="请选择空域类型444"
          >
            <el-option
              v-for="item in drd.cates"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="空域状态:">
          <el-select
            v-model="search_form.status"
            size="mini"
            clearable
            placeholder="请选择空域状态"
          >
            <el-option
              v-for="item in drd.status"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="空域来源:">
          <el-select
            v-model="search_form.source"
            size="mini"
            clearable
            placeholder="请选择空域来源"
          >
            <el-option
              v-for="item in drd.apply"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <div @click="$emit('on-search', search_form)" class="linear-btn">
            搜索
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div style="display: flex; align-items: center">
      <div style="margin-right: 20px">
        <el-upload
          action=""
          accept=".xls,.xlsx"
          :auto-upload="true"
          :show-file-list="false"
          :file-list="fileList"
          :before-upload="beforeAvatarUpload"
          :http-request="(param) => airUpload(param)"
        >
        <span class="linear-btn" v-view="{sign:'basicResources:airspace:import'}" v-if="routeButton1">导入空域</span>
        </el-upload>
      </div>
      <div v-if="routeButton2" v-view="{sign:'basicResources:airspace:visualization'}" class="linear-btn" @click="data.show = true">空域可视化</div>
    </div>

    <div class="allAir">
      <Drawky v-if="data.show" :allairspce="data.show" :readonly="data.show" @clear="clear" />
    </div>
  </div>
</template>

<script>
import Drawky from "@/components/drawky";
import _ from "lodash";
import { props } from "./props";
import API from "@/api";
export default {
  components: {
    Drawky,
  },
  props: _.pick(props, ["onEdit"]),
  data() {
    return {
      isEasy: false,
      search_form: {
        name: null,
        typeId: null,
        source: null,
        status: null,
      },
      drd: {
        cates: [
          { id: 1, title: "电子围栏" },
          { id: 2, title: "禁飞区" },
          { id: 3, title: "限高区" },
        ],
        status: [
          { id: 1, title: "启用" },
          { id: 0, title: "禁用" },
        ],
        apply: [
          { id: 1, title: "自主创建" },
          { id: 0, title: "通用空域" },
        ],
      },
      data: {
        list: null,
        show: false,
        routeButton: false,
        routeButton1: false,
        routeButton2: false
      },
      visible: {
        upload: false,
      },
      fileList: [],
    };
  },
  created () {
    // const permissions = JSON.parse(sessionStorage.getItem("permissions"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("basicResources:airspace:add") > -1) {
      this.routeButton = true
    } 
    if (permissionList.indexOf("basicResources:airspace:import") > -1) {
      this.routeButton1 = true
    } 
    if (permissionList.indexOf("basicResources:airspace:visualization") > -1) {
      this.routeButton2 = true
    }
  },
  methods: {
    clear() {
      this.data.show = false
    },
    // async async_get_list() {
    //   let res = await API.AIRSPACE.selectLikeList({
    //     ...this.search,
    //     pageNo: 1,
    //     pageSize: 10000,
    //   });
    //   if (res.status === 1) {
    //     this.data.list = res.data.data;
    //     this.data.show = true;
    //   } else {
    //     this.$message.error(res.message);
    //   }
    // },
    handleClose(done) {
      done && done();
    },
    beforeAvatarUpload(file) {
      let type = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      if (type.includes(file.type)) {
        return true;
      } else {
        this.$message.error("只能上传.xls .xlsx文件");
        return false;
      }
    },
    async airUpload(file) {
      let res = null;
      let formData = new FormData();
      formData.append("file", file.file);
      try {
        res = await API.AIRSPACE.airUpload(formData);
        if (res.code === 200) {
          this.$message.success("数据添加成功!");
          this.$emit("on-search", this.search_form);
        } else {
          this.$message.error("数据添加失败,请按照模板上传文件!");
        }
        this.fileList = [];
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-task-list-search {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  .allAir {
    z-index: 20;
  }
}
.padding {
  padding-right: 0px !important;
}

::v-deep .el-input {
  width: 178px;
  .el-input__inner {
    width: 100% !important;
  }
}
</style>