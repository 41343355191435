<template>
  <Box>
    <div class="page-service-airList">
      <Search
        @on-edit="onEdit"
        @on-search="handle_search"
      />
      <Table
        class="task-table"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="pagination"
        @size-change="
          (size) => {
            pagination.pageSize = size;
            async_get_list();
          }
        "
        @page-change="
          (page) => {
            pagination.page = page;
            async_get_list();
          }
        "
      >
        <template #action="{ row }">
          <div class="action">
            <span v-if="routeButton3" v-view="{sign:'basicResources:airspace:details'}" title="定位查看" class="iconfont icon-feihangguiji" @click="show(row)"></span>
            <span title="更改" v-view="{sign:'basicResources:airspace:edit'}" v-if="routeButton4 && row.airspaceSource != '通用空域'" class="iconfont icon-genggai" @click="edit(row)"></span>
            <span title="删除"  v-view="{sign:'basicResources:airspace:remove'}" v-if="routeButton5 && row.airspaceSource != '通用空域'" class="iconfont icon-shanchu" @click="del(row)"></span>
          </div>
        </template>
      </Table>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/platform/common/box";
import Search from "./search.vue";
import Table from "@/components/common/table";
import _ from "lodash";
import { props } from "./props";
import API from "@/api";
export default {
  props: _.pick(props, ["onEdit", "edit", "show"]),
  components: { Box, Search, Table },
  methods: {},
  data() {
    return {
      columns: [
        { prop: "name", label: "空域名称" },
        { prop: "typeName", label: "空域类型" },
        { prop: "status", label: "空域状态" },
        { prop: "date", label: "有效期限" },
        { prop: "airspaceSource", label: "空域来源" },
        { prop: "remark", label: "备注" },
        { prop: "editTime", label: "更新时间",  },
        { prop: "action", slot: true, label: "操作" },
      ],
      dataSource: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      search: {},
      map: {
        status: {
          1: "启用",
          0: "禁用"
        },
        airspaceSource: {
          1: "自主创建",
          0: "通用空域"
        }
      },
      routeButton3: false,
      routeButton4: false,
      routeButton5: false,
    };
  },
  methods: {
    async async_get_list() {
      const { map } = this;
      const { page, pageSize } = this.pagination;
      let res = await API.AIRSPACE.selectLikeList({
        ...this.search,
        pageNo: page,
        pageSize: pageSize,
      });
      res = res.data
      this.dataSource = res.data || [];
      this.dataSource = this.dataSource.map((item) => ({
        ...item,
        date: item.noFlyStartTime +'-'+ item.noFlyEndTime,
        status: map.status[item.status],
        airspaceSource: map.airspaceSource[item.airspaceSource]
      }));
      this.pagination.page = res.pageNo;
      this.pagination.total = res.totalCount;
    },
    handle_search(data) {
      this.search = data;
      this.async_get_list();
    },
    async del(data) {
      const res = await API.AIRSPACE.del({
        id: data.id,
        status: 99
      })
      if (res.status === 1) {
        this.$el_message("删除成功");
        if(this.dataSource.length === 1) {
            if(this.pagination.page > 1) {
              this.pagination.page --
            }
          }
        this.async_get_list();
      }
    }
  },
  created () {
    // const permissions = JSON.parse(sessionStorage.getItem("permissions"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("basicResources:airspace:details") > -1) {
      this.routeButton3 = true
    } 
    if (permissionList.indexOf("basicResources:airspace:edit") > -1) {
      this.routeButton4 = true
    } 
    if (permissionList.indexOf("basicResources:airspace:remove") > -1) {
      this.routeButton5 = true
    }
  },
  mounted() {
    this.async_get_list();
  },
};
</script>

<style lang="scss" scoped>
.page-service-airList {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .task-table {
    flex: 1;
    margin-top: 27px;
    .action {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      .iconfont {
        cursor: pointer;
        font-size: 26px;
        color: #9fc4ff;
        margin-right: 4px;
        line-height: 26px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>