<template>
  <div class="flexdleft" :class="gbwy ? 'flexdlefts': '' ">
      <!-- <div class="title"><span>地图区域</span></div> -->
      <div class="row">
      <select class="province changename" name="province">
      </select>
      <select class="city changename" name="city">
      </select>
      <select class="district changename" name="district">
      </select>
      <el-tooltip
        style="margin-left: 10px"
        effect="dark"
        placement="top"
        :open-delay="300"
        :content="`回到${title}`"
      >
        <div class="back" @click="toLaler"></div>
      </el-tooltip>
    </div>

    <div class="left-image" v-if="taskShow">
      <div class="box-name">
        <div class="box-left">
          <div class="box-val">
            <div
              style="
                line-height: 17px;
                height: 17px;
                fontsize: 12px;
                border-bottom: 1px solid #2480ff;
                text-align: center;
                background: #2480ff;
              "
            >
              周
            </div>
            <div
              style="
                line-height: 17px;
                height: 17px;
                fontsize: 12px;
                border-bottom: 1px solid #2480ff;
                text-align: center;
              "
            >
              月
            </div>
            <div
              style="
                line-height: 17px;
                height: 17px;
                fontsize: 12px;
                text-align: center;
              "
            >
              年
            </div>
          </div>
        </div>
        <div class="box-right">
          <i class="shline"></i>
          <div>
            <img src="@/assets/basic-tools/rw1.png" alt="" />
          </div>
          <div style="margin-left: 5px">
            <div style="color: #fb8016; font-size: 12px">执行中</div>
            <div style="color: #d8e7ff; font-size: 20px; margin-top: 5px">
              2
            </div>
          </div>
        </div>
        <div class="box-right">
          <i class="shline"></i>
          <div>
            <img src="@/assets/basic-tools/rw2.png" alt="" />
          </div>
          <div style="margin-left: 5px">
            <div style="color: #16d979; font-size: 12px">待执行</div>
            <div style="color: #d8e7ff; font-size: 20px; margin-top: 5px">
              5
            </div>
          </div>
        </div>
        <div class="box-right">
          <i class="shline"></i>
          <div>
            <img src="@/assets/basic-tools/rw3.png" alt="" />
          </div>
          <div style="margin-left: 5px">
            <div style="color: #009aff; font-size: 12px">已完成</div>
            <div style="color: #d8e7ff; font-size: 20px; margin-top: 5px">
              15
            </div>
          </div>
        </div>
        <div class="box-right">
          <i class="shline"></i>
          <div>
            <img src="@/assets/basic-tools/rw4.png" alt="" />
          </div>
          <div style="margin-left: 5px">
            <div style="color: #f55252; font-size: 12px">已取消</div>
            <div style="color: #d8e7ff; font-size: 20px; margin-top: 5px">
              1
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cityJson from "./city.json";
import { addressInit } from "./jsAddress";
import { Utils } from "@/lib/cesium";
import {mapGetters} from 'vuex'

let location_icon = null;

export default {
  computed:{
    ...mapGetters(['user_info_hn'])
  },
  props: {
    gbwy: {
      typeof: Boolean,
      default: false,
    },
  },
  data() {
    return {
      destination: {
        lng: 120.68240528305054,
        lat: 30.458030121429956,
        alt: 2000,
      },
      title: "海宁市",
      taskShow: false, //任务展示
    };
  },
  methods: {
    getLocation() {
      var city = [];
      city.push(document.querySelector(".province").value);
      city.push(document.querySelector(".city").value);
      city.push(document.querySelector(".district").value);
      // console.log(city)
      var p = cityJson.p;
      var d = cityJson.d;
      var c = cityJson.c;
      // console.log(p,c,d)
      for (var pKey in p) {
        if (p[pKey] == city[0]) {
          //取得对应的省
          var ct = c[pKey];
          for (var cKey in ct) {
            if (ct[cKey] == city[1]) {
              //取得对应的市
              var dt = d[cKey];
              for (var dKey in dt) {
                if (dt[dKey].name == city[2]) {
                  //取得对应的区 并且获取经纬度
                  this.title = dt[dKey].name;
                  return dt[dKey];
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    },
    toLal() {
      let changename = document.querySelectorAll(".changename");
      for (let index = 0; index < changename.length; index++) {
        changename[index].addEventListener("change", () => {
          setTimeout(() => {
            let item = this.getLocation();
            if (item) {
              this.destination.name = item.name;
              this.destination.lng = item.lon;
              this.destination.lat = item.lat;

              let viewer = window.viewer;
              let potiion = Utils.transformWGS842Cartesian({
                lng: this.destination.lng,
                lat: this.destination.lat,
                alt: 2000,
              });
              viewer.camera.flyTo({
                destination: potiion,
              });
              this.init_location_icon(potiion);
            }
          }, 300);
        });
      }
    },
    toLaler() {
      this.timer = setTimeout(() => {
        let item = this.getLocation();
        if (item) {
          this.destination.name = item.name;
          this.destination.lng = item.lon;
          this.destination.lat = item.lat;

          let viewer = window.viewer;
          let potiion = Utils.transformWGS842Cartesian({
            lng: this.destination.lng,
            lat: this.destination.lat,
            alt: 2000,
          });
          viewer.camera.flyTo({
            destination: potiion,
          });
          this.init_location_icon(potiion);
        }
      }, 300);
    },
    init_location_icon(position) {
      let viewer = window.viewer;
      if (location_icon) {
        location_icon.position = position;
      } else {
        location_icon = viewer.entities.add({
          position,
          billboard: {
            width: 36,
            height: 36,
            image: require("@/assets/images/icons/icon_location.png"),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.Bottom,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          },
        });
      }
    },
  },
  mounted() {
    //初始化位置
    addressInit("province", "city", "district", "浙江", "嘉兴市", "海宁市");
    //注册事件
    this.toLal();
    if (this.$route.path === '/home' && this.user_info_hn.departmentId != 60) {
      this.taskShow = true
    }
  },
};
</script>

<style lang="scss" scoped>
.flexdleft {
  position: fixed;
  left: 80px;
  top: 100px;
  transition: all ease-in 700ms;
  .title {
    width: 113px;
    height: 22px;
    background: linear-gradient(90deg, #0f5fa7 0%, rgba(1, 64, 146, 0) 100%);
    color: #b6d2ff;
    border-left: 2px solid #009aff;
    span {
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #b6d2ff;
      line-height: 22px;
      margin-left: 6px;
    }
  }
  .row {
    display: flex;
    margin-top: 8px;
    .city {
      margin: 0 10px;
    }
    .changename {
      width: 96px;
      height: 28px;
      background: rgba(12, 34, 67, 0.8);
      border: 1px solid rgba(0, 154, 255, 0.5);
      color: #9fc4ff;
    }
    .iconfont {
      cursor: pointer;
      font-size: 18px;
      color: #9fc4ff;
    }
    .back {
      width: 28px;
      height: 28px;
      background: center url("~@/assets/home/back.png") no-repeat;
      background-color: rgba(12, 34, 67, 0.8);
    }
  }
}

.flexdlefts {
  left: 500px;
  transition: all ease-out 700ms;
}

.left-image {
  margin-top: 12px;
  left: 300px;
  width: 602px;
  height: 60px;
  background: #0a1a3d;
  opacity: 0.7;
  .box-name {
    display: flex;
    align-items: center;
    height: 60px;
    .box-left {
      width: 70px;
      .box-val {
        margin-left: 10px;
        width: 18px;
        height: 54px;
        border: 1px solid #2480ff;
        color: #e0e6f2;
      }
    }
    .box-right {
      width: 130px;
      display: flex;
      position: relative;
      justify-content: center;
      .shline {
        position: absolute;
        left: 0;
        color: red;
        display: inline-block;
        width: 1px;
        height: 35px;
        border: 1px solid;
        border-image: linear-gradient(
            180deg,
            rgba(0, 154, 255, 0),
            rgba(0, 154, 255, 1),
            rgba(0, 154, 255, 0)
          )
          1 1;
      }
    }
  }
}
</style>