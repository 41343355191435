var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',[_c('div',{staticClass:"page-service-airList"},[_c('Search',{on:{"on-edit":_vm.onEdit,"on-search":_vm.handle_search}}),_c('Table',{staticClass:"task-table",attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource,"pagination":_vm.pagination},on:{"size-change":(size) => {
          _vm.pagination.pageSize = size;
          _vm.async_get_list();
        },"page-change":(page) => {
          _vm.pagination.page = page;
          _vm.async_get_list();
        }},scopedSlots:_vm._u([{key:"action",fn:function({ row }){return [_c('div',{staticClass:"action"},[(_vm.routeButton3)?_c('span',{directives:[{name:"view",rawName:"v-view",value:({sign:'basicResources:airspace:details'}),expression:"{sign:'basicResources:airspace:details'}"}],staticClass:"iconfont icon-feihangguiji",attrs:{"title":"定位查看"},on:{"click":function($event){return _vm.show(row)}}}):_vm._e(),(_vm.routeButton4 && row.airspaceSource != '通用空域')?_c('span',{directives:[{name:"view",rawName:"v-view",value:({sign:'basicResources:airspace:edit'}),expression:"{sign:'basicResources:airspace:edit'}"}],staticClass:"iconfont icon-genggai",attrs:{"title":"更改"},on:{"click":function($event){return _vm.edit(row)}}}):_vm._e(),(_vm.routeButton5 && row.airspaceSource != '通用空域')?_c('span',{directives:[{name:"view",rawName:"v-view",value:({sign:'basicResources:airspace:remove'}),expression:"{sign:'basicResources:airspace:remove'}"}],staticClass:"iconfont icon-shanchu",attrs:{"title":"删除"},on:{"click":function($event){return _vm.del(row)}}}):_vm._e()])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }