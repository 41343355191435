<template>
  <div class="page-device-list">

      <div>
        <div class="top">
          <div class="top_left">
            <div class="top_left_UAV" v-view="{ sign: 'hawk:control:equipmentManage:flightStatistics' }">
              <LeftTop />
            </div>
            <div class="top_left_equipment_work" v-view="{ sign: 'hawk:control:equipmentManage:stateStatistics' }">
              <WorkStatus  />
            </div>
          </div>
          <div class="top_right" v-view="{ sign: 'hawk:control:equipmentManage:uavManage' }">
            <EquStatus />
          </div>
        </div>
        <!-- <div class="bottom_manage">
          <FlyManage  />
        </div> -->
      </div>

  </div>
</template>

<script>
import API from "@/api";
import Box from "@/components/platform/common/box";
import LeftTop from "./top"
import WorkStatus from './workStatus'
import EquStatus from "./equStatus"
import FlyManage from './flyManage'
export default {
  components: {
    Box,
    LeftTop,
    WorkStatus,
    EquStatus,
    FlyManage
  },
  data() {
    return {
      selectList: null,
    };
  },
  methods: {
    // async getAllOrganization() {
    //   let { data } = await API.AIRWAY.getAllOrganizationBack();
    //   this.selectList = this.getTreeData(data);
    // },
    // getTreeData(data) {
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].child.length < 1) {
    //       // children若为空数组，则将children设为undefined
    //       data[i].child = undefined;
    //     } else {
    //       // children若不为空数组，则继续 递归调用 本方法
    //       this.getTreeData(data[i].child);
    //     }
    //   }
    //   return data;
    // },
  },
  mounted() {
    // this.getAllOrganization();
  },
};
</script>
<style lang="scss">
.page-device-list .cpt-service-box .service-box-inner {
  overflow: auto;
}
.bottom_manage .cpt-service-box .service-box-inner {
  overflow: hidden;
}
.bottom_right .cpt-service-box .service-box-inner {
  overflow: hidden;
}
.el-cascader__dropdown {
  .el-cascader-panel {
    .el-scrollbar.el-cascader-menu {
      max-height: 400px;
      overflow-y: auto;
    }
  }
}

.top .select-group .el-cascader {
  line-height: 36px;
}
</style>

<style lang="scss" scoped>
.page-device-list {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 32px;
  // overflow: auto;
  .top {
    height: 662px;
    width: 100%;
    display: flex;
    flex-direction: column;
    &_left {
      display: flex;
      &_UAV {
        width: 794px;
        height: 354px;
      }
      &_equipment_work {
        width: 794px;
        height: 354px;
        // margin-top: 13px;
        margin-left: 12px;
      }
    }
    &_right {
      width: 1600px;
      height: 800px;
      // margin-left: 12px;
      margin-top: 13px;
    }
  }
  .bottom_manage {
    width: 1860px;
    height: 282px;
    margin-top: 13px;
  }
}
</style>