<template>
  <Box>
    <div class="top">
      <div>
        <div class="select-group">
        <el-cascader
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="organId"
          :options="selectList"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          clearable
        >
        </el-cascader>
        <div class="btn" @click="getList">搜索</div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 25%;
            "
          >
            <span class="handle">无人机管理</span>
          </div>
        </div>
      </div>

      <div style="height: 100%">
        <Table class="task-table" :columns="columns" :dataSource="dataSource">
          <template #image="{ row }">
            <div>
              <el-image
                class="image"
                :src="baseUrl + row.image"
                :preview-src-list="[baseUrl + row.image]"
              >
              <div slot="error" style="margin-top:15px">暂未上传图片</div>
              </el-image>
            </div>
          </template>
          <template #reportStatus="{ row }">
            <div>
              <span>{{
                (row.reportStatus == 1 ? "在线" : "离线") || "暂无"
              }}</span>
            </div>
          </template>
          <template #state="{ row }">
            <div>
              <span>{{ changeState(row.state) }}</span>
            </div>
          </template>
          <template #inTime="{ row }">
            <div>
              <span>{{ row.inTime?row.inTime:'暂无' }}</span>
            </div>
          </template>
          <template #outTime="{ row }">
            <div>
              <span>{{ row.outTime?row.outTime:'暂无' }}</span>
            </div>
          </template>
        </Table>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/platform/common/box";
import Table from "@/components/common/table";
import API from "@/api";
import {mapGetters,mapActions} from "vuex"
export default {
  computed:{
    ...mapGetters(["dbox_data"])
  },
  components: {
    Box,
    Table,
  },
  data() {
    return {
      list: null,
      organId: null,
      baseUrl: null,
      selectList:null,
      columns: [
        { prop: "deviceName", label: "无人机名称", width: "168px" },
        { prop: "cateName", label: "无人机型号", width: "168px" },
        // { prop: "deviceSerial", label: "无人机序列号", width: "168px" },
        { prop: "deviceHardId", label: "无人机ID", width: "168px" },
        { prop: "colour", label: "无人机颜色", width: "168px" },
        { prop: "image", label: "无人机图片", slot: true, width: "168px" },
        { prop: "reportStatus", label: "工作状态", slot: true, width: "168px" },
        { prop: "anotherName", label: "所属单位", width: "168px" },
        { prop: "state", label: "设备状态", slot: true, width: "168px" },
        { prop: "inTime", label: "入库时间",slot: true, width: "168px" },
        { prop: "outTime", label: "出库时间",slot: true, width: "168px" },
      ],
      dataSource: [],
    };
  },
  methods: {
    ...mapActions("user",["Dbox"]),
    async getAllOrganization() {
      // let { data } = await API.AIRWAY.getAllOrganizationBack();
      let data  = this.dbox_data
      // console.log(1);
      // console.log(boxdata);
      this.selectList = this.getTreeData(data);
      this.organId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    async getList() {
      const { data } = await API.DEVICE.deviceListWeb({
        organId: this.organId,
        pageSize: 1000,
      });
      this.dataSource = data.data;
    },
    changeState(val) {
      const obj = { 1: "正常", 2: "损坏", 3: "维修", 5: "保养" };
      return obj[val];
    },

  },
 async mounted() {
   await this.getAllOrganization()
   await this.getList();
    // this.getAllOrganization()
   await this.Dbox()
    this.baseUrl = process.env.VUE_APP_WS_IP;
    this.$store.commit('user/FLY_LIST_DATA',this.dataSource)
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
}
.handle {
  background: url(~@/assets/platform/device/header.png) no-repeat;
  width: 528px;
  height: 32px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-group {
  display: flex;
  margin-bottom: 12px;
  ::v-deep .el-cascader {
    box-sizing: border-box;
    width: 176px;
    height: 36px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
    .el-input {
      box-sizing: border-box;
      .el-input__inner {
        height: 36px;
        line-height: 36px;
        background: #03183180;
        border: 1px solid #214884;
        box-sizing: border-box;
        border-radius: 0;
        color: #e0e6f2;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            line-height: 20px;
          }
          .el-select__caret {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.btn {
  width: 82px;
  height: 36px;
  opacity: 0.8;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
.flex {
  display: flex;
  justify-content: center;
}
.image {
  width: 80px;
  height: 40px;
}
</style>