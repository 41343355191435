<template>
  <div class="page-task-list-air">
    <TaskList
      :onEdit="
        () => {
          visible.edit = true;
        }
      "
      :edit="
        (value) => {
          editProp(value);
          visible.edit = true;
        }
      "
      :show="
        (value) => {
          editProp(value);
          visible.edit = true;
          visible.readonly = true;
        }
      "
      ref="listRef"
    />

    <Drawky
      v-if="visible.edit"
      :leftOnclick="(value) => leftOnclick(value)"
      :rigthOnclick="(value) => rigthOnclick(value)"
      :move="(value) => move(value)"
      :airspcelist="editProps"
      :readonly="visible.readonly"
    />
    <!-- 左上角位置跳转栏 -->
    <FiexdLeft v-if="visible.edit"/>
    <el-dialog
      v-if="visible.edit"
      :title="
        editProps ? (visible.readonly ? '查看空域' : '修改空域') : '绘制空域'
      "
      :visible.sync="visible.edit"
      width="400px"
      top="2vh"
      :close-on-click-modal="false"
      :modal="false"
      class="pub_dialog"
      :before-close="handleClose"
    >
      <el-form
        ref="edit_form"
        :rules="rules.edit_form"
        :model="edit_form"
        class="linear-form vertical"
      >
        <el-form-item label="空域名称" prop="name">
          <el-input
            v-model="edit_form.name"
            placeholder="请输入空域名称"
            clearable
            :readonly="visible.readonly"
          />
        </el-form-item>
        <el-form-item label="空域类型" prop="typeId">
          <el-select
            v-model="edit_form.typeId"
            placeholder="请选择空域类型333"
            clearable
            :disabled="visible.readonly"
          >
          <el-option label="电子围栏" :value="1"></el-option>
            <el-option label="限高区" :value="3"></el-option>
            <el-option label="禁飞区" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="色值选择" prop="color">
          <el-color-picker v-model="edit_form.color" style="width:100%"></el-color-picker>
        </el-form-item> -->

        <el-form-item
          label="飞行高度"
          prop="height"
          v-if="edit_form.typeId != 2"
        >
          <div style="display: flex; align-items: center">
            <el-input-number
              v-model="edit_form.height"
              placeholder="请输入飞行高度"
              clearable
              :min="1"
              :max="999999999999999"
              :disabled="visible.readonly"
            />
            <span style="font-size: 16px; color: #fff">M</span>
          </div>
        </el-form-item>

        <!-- <el-form-item label="有效期限" > -->
        <!-- <el-date-picker
            v-model="edit_form.date"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            :readonly="visible.readonly"
          >
          </el-date-picker> -->
        <div class="date">
          <el-form-item label="有效期限" prop="noFlyStartTime">
            <el-date-picker
              size="mini"
              v-model="edit_form.noFlyStartTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择开始时间"
              :disabled="visible.readonly"
            />
          </el-form-item>
          -
          <el-form-item
            label="结束有效期限"
            prop="noFlyEndTime"
            class="dateEnd"
          >
            <el-date-picker
              :picker-options="pickerOptions"
              class="ml20"
              size="mini"
              @focus="datePickerFocus"
              v-model="edit_form.noFlyEndTime"
              type="datetime"
              placeholder="选择结束时间"
              :disabled="visible.readonly"
            />
          </el-form-item>
        </div>

        <!-- </el-form-item> -->
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="edit_form.remark"
            placeholder="请输入备注"
            type="textarea"
            clearable
            :maxlength="255"
            :readonly="visible.readonly"
          />
        </el-form-item>

        <el-form-item label="空域状态" prop="status">
          <el-radio-group v-model="edit_form.status" v-if="!visible.readonly">
            <el-radio-button :label="1">启用</el-radio-button>
            <el-radio-button :label="0">禁用</el-radio-button>
          </el-radio-group>
          <div v-else>
            <el-input v-model="editProps.status" :readonly="visible.readonly" />
          </div>
        </el-form-item>

        <div class="form-submit-item" v-if="!visible.readonly">
          <div @click="handleSave" class="linear-btn">保存</div>
        </div>
      </el-form>
    </el-dialog>

    <!-- 地图切换组件（2D/街道） -->
    <transition
      v-if="visible.edit"
      name="animate__animated dh"
      key="1"
      enter-active-class="animate__fadeInUp"
      leave-active-class="animate__fadeOutDown"
    >
      <Bottomright />
    </transition>
  </div>
</template>

<script>
import Drawky from "@/components/drawky";
import { transformCartesian2WGS84 } from "@/utils/cesium";
import API from "@/api";
import TaskList from "./list";
import Bottomright from "@/components/Bottomright"; //地图切换（2D/街道）组件
import dayjs from "dayjs";
import FiexdLeft from "@/components/fiexdleft";

export default {
  components: {
    TaskList,
    Drawky,
    Bottomright,
    FiexdLeft
  },
  data() {
    return {
      pickerOptions: {}, // 设置时间禁选
      editProps: null,
      visible: {
        edit: false,
        notice: false,
        readonly: false,
      },
      edit_form: {
        name: null,
        typeId: null,
        height: null,
        // date: null,
        remark: null,
        status: 1,
        region: null,
        regionList: null,
        noFlyStartTime: null,
        noFlyEndTime: null,
        // color: null
      },
      rules: {
        edit_form: {
          name: [
            {
              required: true,
              message: "请输入空域名称",
              trigger: "blur",
            },
            { max: 15, message: "空域名称长度不能大于15", trigger: "blur" },
          ],
          typeId: [
            { required: true, message: "请选择空域类型", trigger: "change" },
          ],
          height: [
            { required: true, message: "请输入高度", trigger: "change" },
            {
              type: "number",
              min: 1,
              message: "飞行高度必须大于1m",
              trigger: "blur",
            },
          ],
          // date: [
          //   { required: true, message: "请选择预计时间", trigger: "blur" },
          // ]
          noFlyStartTime: [
            { required: true, message: "请选择开始时间", trigger: "blur" },
          ],
          noFlyEndTime: [
            { required: true, message: "请选择结束时间", trigger: "blur" },
          ],
        },
      },
    };
  },
  methods: {
    datePickerFocus() {
      // 获取焦点时位结束时间做选择限制
      this.pickerOptions = {
        disabledDate: (time) => {
          // 日期选择限制
          if (
            this.edit_form.noFlyStartTime &&
            new Date(this.edit_form.noFlyStartTime).getTime() > Date.now()
          ) {
            return (
              time.getTime() <
              new Date(this.edit_form.noFlyStartTime).getTime() - 8.64e7
            );
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    getNowTime() {
      // 时分秒限制
      let date;
      if (
        this.edit_form.noFlyStartTime &&
        new Date(this.edit_form.noFlyStartTime).getTime() > Date.now()
      ) {
        date = new Date(this.edit_form.noFlyStartTime);
      } else {
        date = new Date();
      }
      let hours = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hours < 10) hours = "0" + hours;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      let timeStr = `${hours}:${minute}:${second}`;
      return timeStr;
    },
    async handleSave() {
      this.$refs["edit_form"].validate(async (valid) => {
        if (!this.edit_form.regionList) {
          // 判断是否绘制了空域
          this.$message.warning("请绘制空域");
        } else if (valid) {
          const { date, ...rest } = this.edit_form;
          let aa = [],
            cc = [];
          this.edit_form.regionList.map((item) =>
            aa.push(transformCartesian2WGS84(item))
          );
          aa.map((item) => cc.push(item.lng, item.lat));
          let zx = cc.concat();
          let first = cc[0],
            last = cc[cc.length - 1];
          zx.shift();
          zx.pop();
          let fv = [];
          let asx = this.oneArrToTwoArr(zx);
          asx.map((item) => fv.push([item[0], item[1]].join(" ")));
          let gg = fv.toString();
          let yg = first + "," + gg + "," + last;

          const params = {
            ...rest,
            noFlyStartTime: dayjs(rest.noFlyStartTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            noFlyEndTime: dayjs(rest.noFlyEndTime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),

            regionList:
              this.editProps &&
              this.editProps.regionList === this.edit_form.regionList
                ? rest.regionList
                : cc,
            region:
              this.editProps && this.editProps.region === yg
                ? rest.region
                : yg &&
                  this.editProps &&
                  this.editProps.region &&
                  !this.edit_form.regionList[0].x
                ? rest.region
                : yg,
            pid: 1,
            regionType: "POLYGON",
            airspaceSource: 1,
          };

          let res;
          if (this.editProps) {
            res = await API.AIRSPACE.edit({ ...params, id: this.editProps.id });
          } else {
            res = await API.AIRSPACE.airspaceAdd(params);
          }

          if (res.status === 1) {
            this.$el_message(this.editProps ? "修改成功" : "创建成功");
            this.$refs["edit_form"].resetFields();
            this.visible.edit = false;
            this.handleClose();
            this.$refs.listRef.async_get_list();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    editProp(value) {
      this.editProps = value;
      this.editProps.regionList = value.regionList;
      this.edit_form.name = value.name;
      this.edit_form.height = value.height;
      this.edit_form.typeId = value.typeId;
      // this.edit_form.date = [value.noFlyStartTime, value.noFlyEndTime]
      this.edit_form.noFlyStartTime = value.noFlyStartTime;
      this.edit_form.noFlyEndTime = value.noFlyEndTime;

      this.edit_form.remark = value.remark;
      this.edit_form.status = this.onStatus(value.status);
      this.edit_form.region = value.region;
      this.edit_form.regionList = value.regionList;
      // this.edit_form.color = value.color;
    },
    // 绘制左击回调
    leftOnclick(data) {
      // if (data.index === -1) {
        this.edit_form.regionList = data.positions;
      // }
    },
    // 绘制右击回调
    rigthOnclick(data) {
      if (data.positions.type === 1) {
        this.edit_form.regionList = null;
      } else {
        this.edit_form.regionList = data.positions;
      }
    },
    // 绘制移动回调
    move(data) {
      this.edit_form.regionList = data.positions;
    },
    // 关闭弹框事件
    handleClose(done) {
      this.$refs["edit_form"].resetFields();
      this.visible.edit = false;
      this.visible.readonly = false;
      this.editProps = null;

      this.edit_form.name = null;
      this.edit_form.typeId = null;
      this.edit_form.height = null;
      this.edit_form.noFlyStartTime = null;
      this.edit_form.noFlyEndTime = null;
      this.edit_form.remark = null;
      this.edit_form.status = 1;
      this.edit_form.region = null;
      this.edit_form.regionList = null;
      done && done();
    },
    oneArrToTwoArr(data) {
      let newData = [];
      for (let i = 0; i < Math.ceil(data.length / 2); i++) {
        newData[i] = [];
        newData[i].push(data[i * 2]);
        newData[i].push(data[i * 2 + 1]);
      }
      return newData;
    },
    onStatus(data) {
      switch (data) {
        case "启用":
          return 1;
          break;
        case "禁用":
          return 0;
          break;
        case "删除":
          return 99;
          break;
        default:
          break;
      }
    },
  },
  watch: {
    "edit_form.noFlyStartTime"(val) {
      // 监视有效期限开始时间，如果大于结束时间，清空结束时间
      if (this.edit_form.noFlyEndTime && val) {
        if (
          new Date(val).getTime() >
          new Date(this.edit_form.noFlyEndTime).getTime()
        ) {
          this.edit_form.noFlyEndTime = null;
        }
      }
    },
    "edit_form.noFlyEndTime"(val) {
      // 监视结束时间
      if (val) {
        const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
        let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (
          this.edit_form.noFlyStartTime &&
          new Date(this.edit_form.noFlyStartTime).getTime() > Date.now()
        ) {
          today = new Date(
            new Date(this.edit_form.noFlyStartTime).setHours(0, 0, 0, 0)
          ).getTime();
        }
        if (date > today) {
          // 如果选的日期就不是和开始时间或是当天同一天
          this.pickerOptions.selectableRange = "00:00:00 - 23:59:59";
        } else {
          // 如果是同一天
          this.pickerOptions.selectableRange = `${this.getNowTime()} - 23:59:59`;
        }
        if (today == new Date(new Date().setHours(0, 0, 0, 0)).getTime()) {
          // 更新一下结束时间 因选择的时候它的时分秒是0
          if (
            new Date(this.edit_form.noFlyEndTime).getHours() !=
              new Date().getHours() &&
            new Date(this.edit_form.noFlyEndTime).getDay() ==
              new Date().getDay() &&
            new Date(this.edit_form.noFlyEndTime).getDate() ==
              new Date().getDate() &&
            new Date(this.edit_form.noFlyEndTime).getHours() == 0
          ) {
            if (
              this.edit_form.noFlyStartTime &&
              new Date(this.edit_form.noFlyStartTime).getTime() > Date.now()
            ) {
              this.edit_form.noFlyEndTime = new Date(
                this.edit_form.noFlyStartTime
              );
            } else {
              this.edit_form.noFlyEndTime = new Date();
            }
          }
        } else {
          if (
            new Date(this.edit_form.noFlyEndTime).getHours() !=
              new Date(this.edit_form.noFlyStartTime).getHours() &&
            new Date(this.edit_form.noFlyEndTime).getDay() ==
              new Date(this.edit_form.noFlyStartTime).getDay() &&
            new Date(this.edit_form.noFlyEndTime).getDate() ==
              new Date(this.edit_form.noFlyStartTime).getDate() &&
            new Date(this.edit_form.noFlyEndTime).getHours() == 0
          ) {
            this.edit_form.noFlyEndTime = new Date(
              this.edit_form.noFlyStartTime
            );
          }
        }
      }
    },
  },
  inject: ["toggle_mode"],
};
</script>

<style lang="scss" scoped>
.page-task-list-air {
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  ::v-deep .el-input .el-input__icon {
    font-size: 16px;
    line-height: 34px;
  }
  .task-list-notice-wrap {
    display: flex;
    .map-box {
      width: 436px;
      height: 274px;
      border: 1px solid red;
      flex-shrink: 0;
      box-sizing: border-box;
      margin-right: 16px;
    }
    .props-box {
      flex: 1;
      box-sizing: border-box;
      .prop-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
        .prop-field-k {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          text-align: left;
          font-weight: 400;
          width: 98px;
        }
        .prop-field-v {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.page-task-list-air .pub_dialog {
  width: 400px;
  position: absolute;
  right: 0;
  top: 0;
  left: auto;
  bottom: auto;
  .headBtn {
    display: flex;
    justify-content: center;
  }
  .el-dialog__body {
    .date {
      display: flex;
      align-items: center;
      .dateEnd {
        .el-form-item__label {
          display: none;
        }
        .el-form-item__content {
          margin-top: 19px;
        }
      }
    }
    .el-color-picker__trigger {
      width: 100%;
    }
  }
}
</style>
