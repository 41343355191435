<template>
  <Box>
    <div class="top">
      <div style="display: flex;justify-content: center;align-items: center"><span class="handle">警员信息</span></div>
      <div class="select-group">
        <el-cascader
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="filter.orgId"
          :options="selectList"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          clearable
        >
        </el-cascader>
        
        <!-- <el-select
          v-model="filter.name"
          size="medium"
          placeholder="请选择警员名称"
          clearable
        >
          <el-option 
          v-for="(item,index) in name"
          :key="index"
          :label="item"
          :value="item"
          > </el-option>

        </el-select> -->
        <el-input
          class="input"
          v-model="filter.name"
          maxlength="20"
          placeholder="请输入警员名称"
          clearable
        />

        <el-select
          v-model="filter.type"
          size="medium"
          placeholder="请选择身份"
          clearable
        >
          <el-option label="民警" :value="0"> </el-option>
          <el-option label="辅警" :value="1"> </el-option>
        </el-select>

        <div class="btn" @click="handle_change">搜索</div>
      </div>
      <div style="display: flex; padding-top: 18px">
        <div class="top_left">
          <div id="device-module-box" class="chart-box" style="display: flex;justify-content: center">
            <div id="device-module-box3" class="chart-box3"></div>
          </div>
        </div>
        <div class="top_right">
          <Table
          class="task-table"
          :columns="columns"
          :dataSource="dataSource" >
          <template #status="{ row }">
              <div>
                <span>{{
                  (row.status == 1 ? "已报备" : "未报备")}}</span>
              </div>
            </template>
            <template #type="{ row }">
              <div>
                <span>{{
                  (row.type == 0 ? "民警" : "辅警") || "暂无"
                }}</span>
              </div>
            </template>
            </Table>
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
let pochart
import Box from "@/components/platform/common/box";
import { Chart } from "@antv/g2";
import Table from "@/components/common/table";
import API from "@/api";
import { mapGetters,mapActions } from 'vuex';
export default {
computed:{
  ...mapGetters(["dbox_data"])
},
  components: {
    Box,
    Table
  },
  data() {
    return {
      selectList:null,
      filter: {
        pageSize: 10000,
        type: null,
        name: null,
        orgId: 1,
      },
      name:[],
      dataList: [],
      list: null,
      music: null,
      columns: [
        { prop: "name", label: "警员名称" },
        { prop: "type", label: "身份", slot: true, },
        { prop: "phone", label: "手机号" },
        { prop: "orgName", label: "所属单位" },
      ],
      dataSource: [],
    };
  },
  methods: {
    ...mapActions("user",["Dbox"]),
    getAllOrganization(){
      let data = this.dbox_data
      this.selectList = this.getTreeData(data);
      this.filter.orgId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data){
      for(let i = 0 ; i< data.length; i++){
        if(data[i].child && data[i].child.length < 1){
          data[i].child = undefined;
        } else{
          if(data[i].child && data[i].child.length < 1){
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    async handle_change() {
      await this.getList()
      pochart.changeData(this.list)
    },
    newChart() {

      pochart = new Chart({
        container: "device-module-box3",
        width: 205,
        height: 205,
      });

      pochart.annotation().text({
        position: ["50%", "50%"],
        content: `局办单位
警员占比`,
        style: {
          fontSize: 20,
          fill: "#CDEDFF",
          textAlign: "center",
        },
      });
      pochart.tooltip({
        showTitle: false,
        showMarkers: false,
      });
      pochart.data(this.list);
      pochart.coordinate("theta", {
        radius: 0.88,
        innerRadius: 0.68,
      });

      pochart.legend({
        itemWidth: 110,
        itemName: {
          style: {
            fill: '#FFFFFF',
          }
        },
        offsetY: 10,
        pageNavigator: {
          marker: {
            style: {
              // 非激活，不可点击态时的填充色设置
              inactiveFill: 'red',
              inactiveOpacity: 0.45,
              // 默认填充色设置
              fill: 'blue',
              opacity: 0.8,
              size: 12,
            },
          },
        },
      })


      pochart
        .interval()
        .adjust("stack")
        .position("count")
        .color("name")

      pochart.render();
    },
    async getList() {
      const { data } = await API.STAFF.Check(this.filter)
      this.dataSource = data.data
      this.name = this.dataSource.map(item=>{
          return item.name
        })
      let list = await API.STAFF.getechartData({ 
        type: this.filter.type, 
        orgId: this.filter.orgId, 
        name: this.filter.name
        })
      this.list = list.data
    },
    async in_chart() {
      await this.getList()
      this.newChart()
    }
  },
  mounted() {
    this.getAllOrganization()
    this.in_chart()
  }
};
</script>

<style lang="scss" scoped>
.top {
    width: 100%;
}
.handle {
          background: url(~@/assets/platform/device/header.png) no-repeat;
          width: 528px;
          height: 32px;
          text-align: center;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

.select-group {
    padding-top: 16px;
    display: flex;
    align-items:center;
  ::v-deep .el-select, ::v-deep .el-cascader,::v-deep .el-input {
    box-sizing: border-box;
    width: 176px;
    height: 36px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
    .el-input {
      box-sizing: border-box;
    .el-input__inner{
        height: 40px;
        line-height: 40px;
        background: #0C224380;
        border: 1px solid #214884;
        box-sizing: border-box;
        border-radius: 0;
        color: #e0e6f2;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            line-height: 20px;
          }
          .el-select__caret {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.btn {
  width: 82px;
  height: 36px;
  opacity: 0.8;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
.flex {
    display: flex;
    justify-content: center;
}
.top_left {
  width: 328px;
  height: 260px;
}
.top_right {
  width: 660px;
  height: 260px;
}
.chart-box3 {
  width: 178px;
  height: 178px;
  box-sizing: border-box;
  margin-right: 16px;
  flex-shrink: 0;
  background: center center url(~@/assets/platform/device/staff.png)
    no-repeat;
  background-size: calc(100% - 1px) calc(100% - 1px);
  box-sizing: border-box;
}

::v-deep .input{
  align-items: center;
  .el-input__inner{
    height: 40px;
    line-height: 40px;
    background: #0C224380;
    border: 1px solid #214884;
    box-sizing: border-box;
    border-radius: 0;
    color: #e0e6f2;
  }
}
</style>