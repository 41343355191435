export const props = {
  onEdit: {
    type: Function,
    default: () => () => {},
  },
  onClose: {
    type: Function,
    default: () => () => {},
  },
  edit: {
    type: Function,
    default: () => () => {},
  },
  show: {
    type: Function,
    default: () => () => {},
  }, 
  list: {
    type: Object,
    default: {}
  }
};
