<template>
  <Box>
    <div class="top">
      <div style="display: flex;justify-content: center;align-items: center"><span class="handle">无人机飞行统计</span></div>
      <div class="select-group">
        <el-cascader
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="filter.organId"
          :options="selectList"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          clearable
        >
        </el-cascader>

        <div class="btn" @click="getList">搜索</div>
      </div>
      <div style="display: flex; padding-top: 18px;justify-content: space-evenly;">
        <div v-for="item in dataList" :key="item.id">
            <div class="flex"><img :src="item.url" /></div>
            <div class="flex" style="color: #a2c4fd; font-size: 30px;line-height:60px">{{ item.value === '0.0000' ? '0' : item.value }}</div>
            <div class="flex" style="color: #6a99e9; font-size: 20px">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/platform/common/box";
import frequency from "@/assets/platform/device/frequency.png";
import km from "@/assets/platform/device/km.png";
import time from "@/assets/platform/device/time.png";
import {mapGetters,mapActions} from "vuex"
import API from "@/api";
export default {
  computed:{
    ...mapGetters(["dbox_data"])
  },
  components: {
    Box,
  },
  data() {
    return {
      list: null,
      music: null,
      selectList:null,
      filter: {
        organId: 1,
        type: 3,
      },
      dataList: [
        {
          id: 1,
          type: 1,
          // text: "无人机飞行架次统计(次)",
          text: "飞行总架次(次)",
          url: frequency,
          value: null,
        },
        {
          id: 2,
          type: 2,
          // text: "无人机飞行里程统计(km)",
          text: "飞行总里程(m)",
          url: km,
          value: null,
        },
        {
          id: 3,
          type: 3,
          text: "无人机飞行时间统计",
          text: "飞行总时长",
          url: time,
          value: null,
        },
      ],
    };
  },
  methods: {
    ...mapActions("user",["Dbox"]),
    getAllOrganization() {
      // let { data } = await API.AIRWAY.getAllOrganizationBack();
      let data  = this.dbox_data
      this.selectList = this.getTreeData(data);
      this.filter.organId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    async getList() {
      const { data } = await API.DEVICE.FlightInfo(this.filter)
      this.dataList[0].value = data[0].data;
      this.dataList[1].value = data[1].data;
      this.dataList[2].value = data[2].data;
      if (data[2].data != null) {
        this.dataList[2].value = this.formatSeconds(data[2].data)
      } else {
        this.dataList[2].value = '暂无'
      }
      
      
    },
    formatSeconds(value) {
      let h = parseInt(value / 60 / 60)
      // h = h < 10 ? '0' + h : h
      let m = parseInt(value / 60 % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(value % 60)
      s = s < 10 ? '0' + s : s
      // 作为返回值返回
      return h +':' + m + ':' + s 
    }   
  },
  mounted() {
    this.getAllOrganization()
    this.getList()
    this.Dbox()
  },
};
</script>

<style lang="scss" scoped>
.top {
    width: 100%;
}
.handle {
          background: url(~@/assets/platform/device/header.png) no-repeat;
          width: 528px;
          height: 32px;
          text-align: center;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

.select-group {
    padding-top: 16px;
    display: flex;
  ::v-deep .el-cascader {
    box-sizing: border-box;
    width: 176px;
    height: 36px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
    .el-input {
      box-sizing: border-box;
      .el-input__inner {
        height: 36px;
        line-height: 36px;
        background: #03183180;
        border: 1px solid #214884;
        box-sizing: border-box;
        border-radius: 0;
        color: #e0e6f2;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            line-height: 20px;
          }
          .el-select__caret {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.btn {
  width: 82px;
  height: 36px;
  opacity: 0.8;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
.flex {
    display: flex;
    justify-content: center;
}
</style>