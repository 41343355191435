<template>
  <Box>
    <div class="top">
      <div style="display: flex; justify-content: center; align-items: center">
        <span class="handle">服务局办</span>
      </div>
      <div class="select-group">
        <el-cascader
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="params.orgId"
          :options="selectList"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          clearable
        >
        </el-cascader>
          <el-input
          class="input"
          v-model="params.name"
          maxlength="20"
          placeholder="请输入运维名称"
          clearable
        />

        <div class="btn" @click="handle_change">搜索</div>
      </div>

      <div style="display: flex; padding-top: 18px">
        <div class="top_left">
          <div id="device-module-box" class="chart-box" style="display: flex;justify-content: center;height: 100%;">
            <div id="device-module-box1" class="chart-box1"></div>
          </div>
        </div>
        <div class="top_right">
          <Table
            class="task-table"
            :columns="columns"
            :dataSource="dataSource"
          />
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
let serchart;
import Box from "@/components/platform/common/box";
import { Chart } from "@antv/g2";
import Table from "@/components/common/table";
import API from "@/api";
import {mapGetters,mapActions} from "vuex"
export default {
  computed:{
    ...mapGetters(["dbox_data"])
  },
  components: {
    Box,
    Table,
  },
  data() {
    return {
      selectList:null,
      ywname:[],
      params: {
        type: 2,
        orgId: 1,
        name:null,
        pageSize: 1000
      },
      type: 2,
      list: null,
      columns: [
        { prop: "name", label: "运维名称" },
        { prop: "phone", label: "手机号" },
        { prop: "orgName", label: "所属单位" },
      ],
      dataSource: [],
    };
  },
  methods: {
    ...mapActions("user",["Dbox"]),
    getAllOrganization() {
      // let { data } = await API.AIRWAY.getAllOrganizationBack();
      let data  = this.dbox_data
      this.selectList = this.getTreeData(data);
      this.params.orgId  = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    async handle_change() {
      await this.getPerList()
      serchart.changeData(this.list)
    },
    newChart() {
      serchart = new Chart({
        container: "device-module-box1",
        // width: 178,
        // height: 178,
        width: 205,
        height: 205,
      });
      serchart.annotation().text({
        position: ["50%", "50%"],
        content: `服务局办
人员占比`,
        style: {
          fontSize: 20,
          fill: "#CDEDFF",
          textAlign: "center",
        },
      });
      
      serchart.tooltip({
        showTitle: false,
        showMarkers: false,
      });
      serchart.data(this.list);
      serchart.coordinate("theta", {
        radius: 0.85,
        innerRadius: 0.68,
      });
      serchart.legend({
        itemWidth: 110,
        itemName: {
          style: {
            fill: '#FFFFFF',
          }
        },
        offsetY: 10,
        pageNavigator: {
          marker: {
            style: {
              // 非激活，不可点击态时的填充色设置
              inactiveFill: 'red',
              inactiveOpacity: 0.45,
              // 默认填充色设置
              fill: 'blue',
              opacity: 0.8,
              size: 12,
            },
          },
        },
        onClick: (ev) => {
          console.log(22, ev);
        }
      })
      serchart.on('legend:click', (ev, data) => console.log(ev))
      
      serchart
        .interval()
        .adjust("stack")
        .position("count")
        .color("name");

      serchart.render();
    },

    async getPerList() {
      const { data } = await API.STAFF.Check(this.params)
      this.dataSource = data.data
      let list = await API.STAFF.getechartData({ type: 2, 
        orgId: this.params.orgId ,
        name:this.params.name
      })
      this.list = list.data
    },
    async init_chart() {
      const { data } = await API.STAFF.Check(this.params)
      this.dataSource = data.data
      let list = await API.STAFF.getechartData({ type: 2, 
        orgId: this.params.orgId ,
        name:this.params.name
      })
      this.list = list.data
      this.newChart()
    },
    async csh(){
    const name = await API.STAFF.operatorList()
    this.ywname  = name.data.map(item=>{
        return item.name
      })
    }
  },
  mounted() {
    this.getAllOrganization()
    this.init_chart()
    this.csh()
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
}
.handle {
  background: url(~@/assets/platform/device/header.png) no-repeat;
  width: 528px;
  height: 32px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-group {
    padding-top: 16px;
    display: flex;
  ::v-deep .el-select, ::v-deep .el-cascader,::v-deep .el-input {
    box-sizing: border-box;
    width: 176px;
    height: 36px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
    .el-input {
      box-sizing: border-box;
      .el-input__inner {
        height: 36px;
        line-height: 36px;
        background: #0C224380;
        border: 1px solid #214884;
        box-sizing: border-box;
        border-radius: 0;
        color: #e0e6f2;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            line-height: 20px;
          }
          .el-select__caret {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.btn {
  width: 82px;
  height: 36px;
  opacity: 0.8;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
.flex {
  display: flex;
  justify-content: center;
}
.top_left {
  width: 328px;
  height: 260px;
}
.top_right {
  width: 660px;
  height: 260px;
}
.chart-box1 {
  width: 178px;
  height: 178px;
  box-sizing: border-box;
  margin-right: 16px;
  flex-shrink: 0;
  background: center center url(~@/assets/platform/device/staff.png) no-repeat;
  background-size: calc(100% - 1px) calc(100% - 1px);
  box-sizing: border-box;
}
.chart-value {
  flex: 1;
  box-sizing: border-box;
  .status-list {
    display: flex;
    flex-direction: column;
    .status-item {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 10px;
      margin-bottom: 4px;
      &-name {
        font-size: 12px;
        color: #caf2ff;
        font-weight: 400;
        display: flex;
        align-items: center;
        &-color {
          background: #000;
          width: 9px;
          height: 9px;
          border-radius: 9px;
          margin-right: 8px;
        }
      }
      &-value {
        font-size: 12px;
        color: #caf2ff;
        text-align: right;
        font-weight: 400;
      }
    }
  }
}
::v-deep .input{
  align-items: center;
  .el-input__inner{
    height: 40px;
    line-height: 40px;
    background: #0C224380;
    border: 1px solid #214884;
    box-sizing: border-box;
    border-radius: 0;
    color: #e0e6f2;
  }
}
</style>