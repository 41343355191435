<template>
  <div class="page-staff-list">
      <div>
        <div class="top">
          <div class="top_left" v-view="{ sign: 'hn:sky:personnelBoard:serviceBureau' }">
            <Service />
          </div>
          <div class="top_right" v-view="{ sign: 'hn:sky:personnelBoard:personnelStatistics' }">
            <Personnel />
          </div>
        </div>
        <div class="bottom">
          <div class="bottom_left" v-view="{ sign: 'hn:sky:personnelBoard:policeInformation' }">
            <Policea />
          </div>
          <div class="bottom_right" v-view="{ sign: 'hn:sky:personnelBoard:performanceRanking' }">
            <Achievements />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import API from "@/api";
import Box from "@/components/platform/common/box";
import Service from "./service";
import Personnel from "./personnel";
import Policea from "./policea";
import Achievements from "./achievements";
export default {
  components: {
    Box,
    Service,
    Personnel,
    Policea,
    Achievements,
  },
  data() {
    return {
      selectList: null,
    };
  },
  methods: {
    // async getAllOrganization() {
    //   let { data } = await API.AIRWAY.getAllOrganizationBack();
    //   this.selectList = this.getTreeData(data);
    // },
    // getTreeData(data) {
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].child.length < 1) {
    //       // children若为空数组，则将children设为undefined
    //       data[i].child = undefined;
    //     } else {
    //       // children若不为空数组，则继续 递归调用 本方法
    //       this.getTreeData(data[i].child);
    //     }
    //   }
    //   return data;
    // },
  },
  mounted() {
    // this.getAllOrganization();
  },
};
</script>

<style lang="scss">
.page-staff-list .chart-box1 div {
  margin-left: -13px;
}

.page-staff-list .chart-box2 div {
  margin-left: -13px;
}

.page-staff-list .chart-box3 div {
  margin-left: -13px;
}

.page-staff-list .cpt-service-box .service-box-inner {
  overflow: auto;
  padding-bottom: 78px;
}
.bottom_right .cpt-service-box .service-box-inner {
  overflow: hidden;
}
.el-cascader__dropdown {
  .el-cascader-panel {
    .el-scrollbar.el-cascader-menu {
      max-height: 400px;
      overflow-y: auto;
    }
  }
}
</style>

<style lang="scss" scoped>
.page-staff-list {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 32px;
  width: 100%;
  overflow: auto;
  .top {
    height: 431px;
    width: 100%;
    display: flex;

    &_left {
      width: 1026px;
      height: 431px;
    }
    &_right {
      width: 916px;
      height: 431px;
      margin-left: 24px;
    }
  }
  .bottom {
    width: 100%;
    height: 479px;
    margin-top: 24px;
    display: flex;
    &_left {
      width: 1026px;
      height: 431px;
    }
    &_right {
      width: 916px;
      height: 431px;
      margin-left: 24px;
    }
  }
}
</style>