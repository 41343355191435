import JSZip from 'jszip'
export function getTime(time) {
  // 转换为式分秒
  let h = parseInt(time / 60 / 60 % 24)
  // h = h < 10 ? '0' + h : h
  let m = parseInt(time / 60 % 60)
   m = m < 10 ? '0' + m : m
  let s = parseInt(time % 60)
   s = s < 10 ? '0' + s : s
  // 作为返回值返回
  return h +':' + m + ':' + s 
}


// 作业历史预警图片打包下载
export function packageImages(imgList) {
  var imgBase64 = [];
			var imageSuffix = [];//图片后缀
			var zip = new JSZip();
		    var img = zip.folder("images");
		    
			for(var i=0;i<imgList.length;i++){
				let src = imgList[i];
				let suffix = src.substring(src.lastIndexOf("/"));
				imageSuffix.push(suffix);
				
				 getBase64(imgList[i])
		            .then(function(base64){
		            	imgBase64.push(base64.substring(22));
		            	 
		            },function(err){
		                  console.log(err);//打印异常信息
		            }); 
		           
			}
			function tt(){
				setTimeout(function(){
					if(imgList.length == imgBase64.length){
						for(var i=0;i<imgList.length;i++){
							img.file(imageSuffix[i], imgBase64[i], {base64: true});
						}
						zip.generateAsync({type:"blob"}).then(function(content) {
					        saveAs(content, "images.zip");
					    });
					    
					}else{
						tt();
					}
				},100);
				
			}
			tt();
}


//传入图片路径，返回base64
function getBase64(img){
  function getBase64Image(img,width,height) {
    var canvas = document.createElement("canvas");
    canvas.width = width ? width : img.width;
    canvas.height = height ? height : img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    var dataURL = canvas.toDataURL();
    return dataURL;
  }
  var image = new Image();
  image.crossOrigin = 'Anonymous';
  image.src = img;
  var deferred=$.Deferred();
  if(img){
    image.onload =function (){
      deferred.resolve(getBase64Image(image));
    }
    return deferred.promise();
  }
}