<template>
  <Box>
    <div class="top">
      <div style="display: flex; justify-content: center; align-items: center">
        <span class="handle">绩效排名</span>
      </div>
      <div style="display: flex; padding-top: 28px">
        <div
          :class="type === 2 ? 'btnSelect' : 'btn'"
          @click="handle_search(2)"
        >
          单位排名
        </div>
        <div
          :class="type === 1 ? 'btnSelect' : 'btn'"
          @click="handle_search(1)"
        >
          个人排名
        </div>
      </div>
      <div style="display: flex; padding-top: 18px; height: 100%">
        <Table
          class="task-table"
          :columns="type === 2 ? company : personal"
          :dataSource="dataSource"
        >
        
        <template #totalTime="{ row }">
          <div class="action">
            {{ getTime(row.totalTime) === "00s" ? '暂无' : getTime(row.totalTime) }}
          </div>
        </template>
        </Table>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/platform/common/box";
import Table from "@/components/common/table";
import { getTime }  from '@/utils/unti.js'
import API from "@/api";
export default {
  components: {
    Box,
    Table,
  },
  data() {
    return {
      type: 2,
      company: [
        { prop: "index", label: "排名" },
        { prop: "anotherName", label: "单位名称" },
        { prop: "totalCount", label: "发布需求数" },
        { prop: "totalTime", slot: true, label: "飞行总时长" },
        { prop: "totalMileage", label: "飞行总里程" },
        { prop: "endTime", label: "最新发布时间" },
      ],
      personal: [
        { prop: "index", label: "排名" },
        { prop: "name", label: "个人排名" },
        { prop: "totalCount", label: "飞行总任务数" },
        { prop: "totalTime", slot: true, label: "飞行总时长" },
        { prop: "totalMileage", label: "飞行总里程" },
        { prop: "endTime", label: "最新发布时间" },
      ],
      dataSource: [],
    };
  },
  methods: {
    getTime,
    async getList() {
      this.dataSource = []
      const { data } = await API.STAFF.Rank({ type: this.type });
      data.forEach(v => {
        v.totalMileage = v.totalMileage + ' m' 
      });
      this.dataSource = data;
      // this.dataSource.map((item,index)  => item.index = index+1 )
    },
    handle_search(val) {
      this.type = val;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 916px;
}
.handle {
  background: url(~@/assets/platform/device/header.png) no-repeat;
  width: 528px;
  height: 32px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-group {
  padding-top: 16px;
  display: flex;
  ::v-deep .el-select {
    box-sizing: border-box;
    width: 176px;
    height: 36px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
    .el-input {
      box-sizing: border-box;
      .el-input__inner {
        height: 36px;
        line-height: 36px;
        background: #0c224380;
        border: 1px solid #214884;
        box-sizing: border-box;
        border-radius: 0;
        color: #e0e6f2;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            line-height: 20px;
          }
          .el-select__caret {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.btn {
  width: 132px;
  height: 32px;
  opacity: 0.8;
  margin-right: 16px;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
.btnSelect {
  width: 132px;
  height: 32px;
  opacity: 0.8;
  margin-right: 16px;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0.5) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
.flex {
  display: flex;
  justify-content: center;
}

</style>