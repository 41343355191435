var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',[_c('div',{staticClass:"top"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('span',{staticClass:"handle"},[_vm._v("警员信息")])]),_c('div',{staticClass:"select-group"},[_c('el-cascader',{staticClass:"input-orgname",attrs:{"placeholder":"请选择所属单位","options":_vm.selectList,"show-all-levels":false,"props":{
          children: 'child',
          label: 'name',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
          empty: false
        },"clearable":""},model:{value:(_vm.filter.orgId),callback:function ($$v) {_vm.$set(_vm.filter, "orgId", $$v)},expression:"filter.orgId"}}),_c('el-input',{staticClass:"input",attrs:{"maxlength":"20","placeholder":"请输入警员名称","clearable":""},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}}),_c('el-select',{attrs:{"size":"medium","placeholder":"请选择身份","clearable":""},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}},[_c('el-option',{attrs:{"label":"民警","value":0}}),_c('el-option',{attrs:{"label":"辅警","value":1}})],1),_c('div',{staticClass:"btn",on:{"click":_vm.handle_change}},[_vm._v("搜索")])],1),_c('div',{staticStyle:{"display":"flex","padding-top":"18px"}},[_c('div',{staticClass:"top_left"},[_c('div',{staticClass:"chart-box",staticStyle:{"display":"flex","justify-content":"center"},attrs:{"id":"device-module-box"}},[_c('div',{staticClass:"chart-box3",attrs:{"id":"device-module-box3"}})])]),_c('div',{staticClass:"top_right"},[_c('Table',{staticClass:"task-table",attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource},scopedSlots:_vm._u([{key:"status",fn:function({ row }){return [_c('div',[_c('span',[_vm._v(_vm._s((row.status == 1 ? "已报备" : "未报备")))])])]}},{key:"type",fn:function({ row }){return [_c('div',[_c('span',[_vm._v(_vm._s((row.type == 0 ? "民警" : "辅警") || "暂无"))])])]}}])})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }