<template>
  <Box>
    <div class="top">
      <div style="display: flex;justify-content: center;align-items: center"><span class="handle">人员统计</span></div>
      <div class="select-group">
        <el-cascader
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="organId"
          :options="selectList"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          clearable
        >
        </el-cascader>
        
        <div class="btn" @click="handle_change">搜索</div>
      </div>
      <div style="display: flex; padding-top: 18px">
        <div class="top_left">
          <div id="device-module-box" class="chart-box" style="display: flex;justify-content: center;height: 100%;">
            <div id="device-module-box2" class="chart-box2"></div>
          </div>
        </div>
        <div class="top_right">
          <Table
        class="task-table"
        :columns="columns"
        :dataSource="dataSource"
        :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
        />
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
let chart2;
import Box from "@/components/platform/common/box";
import { Chart } from "@antv/g2";
import Table from "@/components/common/table";
import API from "@/api";
import {mapGetters,mapActions} from "vuex"
export default {
computed:{
  ...mapGetters(["dbox_data"])
},
  components: {
    Box,
    Table
  },
  data() {
    return {
      selectList:null,
      list: null,
      organId: 1,
      columns: [
        { prop: "anotherName", label: "单位名称" },
        { prop: "count", label: "人员总数" ,id:1},
        { prop: "flyingHandCount", label: "运维人员" ,id:1},
        { prop: "mingjingCount", label: "民警",id :1},
        { prop: "fujingCount", label: "辅警" ,id:1},
        
      ],
      dataSource: [],
      dataPerson: [
        { name: '运维人员', value: 0 },
        { name: '民警', value: 0 },
        { name: '辅警', value: 0 },
      ],
    };
  },
  methods: {
    ...mapActions("user",["Dbox"]),

    getAllOrganization() {
      // let { data } = await API.AIRWAY.getAllOrganizationBack();
      let data  = this.dbox_data
      this.selectList = this.getTreeData(data);
      this.organId  = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    async handle_change() {
      await this.getList()
      chart2.changeData(this.dataPerson)
    },
    newChart() {
      chart2 = new Chart({
        container: "device-module-box2",
        width: 205,
        height: 205,
      });

      chart2.annotation().text({
        position: ["50%", "50%"],
        content: `身份人员
占比`,
        style: {
          fontSize: 20,
          fill: "#CDEDFF",
          textAlign: "center",
        },
      });

      chart2.tooltip({
        showTitle: false,
        showMarkers: false,
      });
      chart2.data(this.dataPerson);
      chart2.coordinate("theta", {
        radius: 0.85,
        innerRadius: 0.68,
      });
      chart2.legend({
        itemName: {
          style: {
            fill: '#FFFFFF',
          }
        },
        offsetY: 10,
        pageNavigator: {
          marker: {
            style: {
              // 非激活，不可点击态时的填充色设置
              inactiveFill: 'red',
              inactiveOpacity: 0.45,
              // 默认填充色设置
              fill: 'blue',
              opacity: 0.8,
              size: 12,
            },
          },
        },
      })

      chart2
        .interval()
        .adjust("stack")
        .position("value")
        .color("name", ["#246AE2", "#2ECBE2", "#DC872B"]);
      chart2.render();
    },
    async getList() {
      let { data } = await API.STAFF.userCount({ organId: this.organId })
      this.dataSource = data[0].allChild
      this.dataPerson[0].value = data[0].flyingHandCount
      this.dataPerson[1].value = data[0].mingjingCount
      this.dataPerson[2].value = data[0].fujingCount
    },
    async init_chart() {
      await this.getList()
      this.newChart()
    }
  },
  mounted() {
    this.getAllOrganization()
    this.init_chart()
  }
};
</script>

<style lang="scss" scoped>
.top {
    width: 916px;
}
.handle {
          background: url(~@/assets/platform/device/header.png) no-repeat;
          width: 528px;
          height: 32px;
          text-align: center;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

.select-group {
    padding-top: 16px;
    display: flex;
  ::v-deep .el-cascader {
    box-sizing: border-box;
    width: 176px;
    height: 36px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
    .el-input {
      box-sizing: border-box;
      .el-input__inner {
        height: 36px;
        line-height: 36px;
        background: #0C224380;
        border: 1px solid #214884;
        box-sizing: border-box;
        border-radius: 0;
        color: #e0e6f2;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            line-height: 20px;
          }
          .el-select__caret {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.btn {
  width: 82px;
  height: 36px;
  opacity: 0.8;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
.flex {
    display: flex;
    justify-content: center;
}
.top_left {
  width: 328px;
  height: 260px;
}
.top_right {
  width: 500px;
  height: 260px;
}
.chart-box2 {
  width: 178px;
  height: 178px;
  box-sizing: border-box;
  margin-right: 16px;
  flex-shrink: 0;
  background: center center url(~@/assets/platform/device/staff.png)
    no-repeat;
  background-size: calc(100% - 1px) calc(100% - 1px);
  box-sizing: border-box;
}
</style>