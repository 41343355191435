var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',[_c('div',{staticClass:"top"},[_c('div',[_c('div',{staticClass:"select-group"},[_c('el-cascader',{staticClass:"input-orgname",attrs:{"placeholder":"请选择所属单位","options":_vm.selectList,"show-all-levels":false,"props":{
          children: 'child',
          label: 'name',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
          empty: false
        },"clearable":""},model:{value:(_vm.organId),callback:function ($$v) {_vm.organId=$$v},expression:"organId"}}),_c('div',{staticClass:"btn",on:{"click":_vm.getList}},[_vm._v("搜索")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","margin-left":"25%"}},[_c('span',{staticClass:"handle"},[_vm._v("无人机管理")])])],1)]),_c('div',{staticStyle:{"height":"100%"}},[_c('Table',{staticClass:"task-table",attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource},scopedSlots:_vm._u([{key:"image",fn:function({ row }){return [_c('div',[_c('el-image',{staticClass:"image",attrs:{"src":_vm.baseUrl + row.image,"preview-src-list":[_vm.baseUrl + row.image]}},[_c('div',{staticStyle:{"margin-top":"15px"},attrs:{"slot":"error"},slot:"error"},[_vm._v("暂未上传图片")])])],1)]}},{key:"reportStatus",fn:function({ row }){return [_c('div',[_c('span',[_vm._v(_vm._s((row.reportStatus == 1 ? "在线" : "离线") || "暂无"))])])]}},{key:"state",fn:function({ row }){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.changeState(row.state)))])])]}},{key:"inTime",fn:function({ row }){return [_c('div',[_c('span',[_vm._v(_vm._s(row.inTime?row.inTime:'暂无'))])])]}},{key:"outTime",fn:function({ row }){return [_c('div',[_c('span',[_vm._v(_vm._s(row.outTime?row.outTime:'暂无'))])])]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }