var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',[_c('div',{staticClass:"top"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('span',{staticClass:"handle"},[_vm._v("无人机飞行统计")])]),_c('div',{staticClass:"select-group"},[_c('el-cascader',{staticClass:"input-orgname",attrs:{"placeholder":"请选择所属单位","options":_vm.selectList,"show-all-levels":false,"props":{
          children: 'child',
          label: 'name',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
          empty: false
        },"clearable":""},model:{value:(_vm.filter.organId),callback:function ($$v) {_vm.$set(_vm.filter, "organId", $$v)},expression:"filter.organId"}}),_c('div',{staticClass:"btn",on:{"click":_vm.getList}},[_vm._v("搜索")])],1),_c('div',{staticStyle:{"display":"flex","padding-top":"18px","justify-content":"space-evenly"}},_vm._l((_vm.dataList),function(item){return _c('div',{key:item.id},[_c('div',{staticClass:"flex"},[_c('img',{attrs:{"src":item.url}})]),_c('div',{staticClass:"flex",staticStyle:{"color":"#a2c4fd","font-size":"30px","line-height":"60px"}},[_vm._v(_vm._s(item.value === '0.0000' ? '0' : item.value))]),_c('div',{staticClass:"flex",staticStyle:{"color":"#6a99e9","font-size":"20px"}},[_vm._v(_vm._s(item.text))])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }