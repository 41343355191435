<template>
  <Box>
    <div class="top">
      <div style="display: flex; justify-content: center; align-items: center">
        <span class="handle">设备状态统计</span>
      </div>
      <!-- <div class="select-group">
        <el-select
          v-model="type"
          size="medium"
          placeholder="请选择"
        >
          <el-option label="无人机" :value="1">无人机</el-option>
          <el-option label="挂载" :value="3">挂载</el-option>
          <el-option label="电池" :value="4">电池</el-option>
        </el-select>
        <div class="btn" @click="typeChange">搜索</div>
      </div>
      <div>
        <div id="task-chart" class="task-chart"></div>
      </div> -->



      <div style="display: flex;margin-bottom: 17px">
        <div class="select-group">
          <el-cascader v-if="this.active < 3 "
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="seach.organId"
          :options="selectList"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          clearable
        >
        </el-cascader>
        </div>
        <div class="select-group" style="padding-left: 12px">
          <el-select
            v-model="active"
            size="medium"
            placeholder="电池管理"
            @change="activeChange"
          >
            <el-option label="无人机" :value="0">无人机</el-option>
            <el-option label="电池" :value="1">电池</el-option>
            <el-option label="挂载" :value="2">挂载</el-option>
            <el-option label="基站" :value="3">基站</el-option>
          </el-select>
        </div>
        
        <div class="btn" @click="change" style="margin: 16px  0 0 6px;">搜索</div>
      </div>
      <div>
          <!-- 无人机选项 -->
          <Table v-if="active === 0"
            class="task-table"
            style="max-height:420px"
            :columns="columns4"
            :dataSource="dataSource">
            <template #image="{ row }">
              <div>
                <el-image
                  class="image"
                  :src="baseUrl + row.image"
                  :preview-src-list="[baseUrl + row.image]"
                >
                <div slot="error" style="margin-top:15px">暂未上传图片</div>
                </el-image>
              </div>
            </template>
            <template #state="{ row }">
            <div>
              <span>{{ changeState(row.state) }}</span>
            </div>
            </template>
             <template #store="{ row }">
            <div>
              <span>{{ changeStore(row.store) }}</span>
            </div>
            </template>
            </Table>

          <Table v-if="active === 1"
            class="task-table"
            style="max-height:420px"
            :columns="columns"
            :dataSource="dataSource" />

            <Table v-if="active === 2"
            class="task-table"
            style="max-height:420px"
            :columns="columns2"
            :dataSource="dataSource" >
              <template #image="{ row }">
              <div>
                <el-image
                  class="image"
                  :src="baseUrl + row.image"
                  :preview-src-list="[baseUrl + row.image]"
                >
                </el-image>
              </div>
            </template>
            <template #state="{ row }">
            <div>
              <span>{{ changeState(row.state) }}</span>
            </div>
          </template>
            </Table>

            <Table v-if="active === 3"
            class="task-table"
            style="max-height:420px"
            :columns="columns3"
            :dataSource="dataSource" >
            <template #image="{ row }">
              <div>
                <el-image
                  class="image"
                  :src="baseUrl + row.image"
                  :preview-src-list="[baseUrl + row.image]"
                >
                </el-image>
              </div>
            </template>
            <template #state="{ row }">
            <div>
              <span>{{ changeState(row.state) }}</span>
            </div>
          </template>
            </Table>
      </div>
    </div>
  </Box>
</template>

<script>
let chart;
import Box from "@/components/platform/common/box";
import { Chart } from "@antv/g2";
import Table from "@/components/common/table";
import API from "@/api";
import {mapGetters,mapActions} from "vuex"
export default {
    computed:{
    ...mapGetters(["dbox_data"])
  },
  components: {
    Box,
    Table
  },
  data() {
    return {
      selectList:null,
      seach: {
        pageSize: 10000,
        organId: 1,
      },
      list: null,
      active: 0,
      activeSave:0,
      type: 1,
      baseUrl: null,
      columns: [
        { prop: "batteryName", label: "电池名称" },
        { prop: "battery_id", label: "电池编号" },
        { prop: "deviceName", label: "归属无人机" },
        { prop: "organizationName", label: "所属单位" },
        { prop: "duration_str", label: "电池累计时长" },
        { prop: "flightSorties", label: "累计飞行架次" },
      ],
      columns2: [
        { prop: "name", label: "挂载名称" },
        { prop: "image", label: "挂载图片", slot: true },
        { prop: "state", label: "挂载状态", slot: true },
        { prop: "deviceName", label: "归属无人机" },
        { prop: "orgName", label: "所属单位" },
        { prop: "inTime", label: "入库时间" },
        { prop: "outTime", label: "出库时间" },
      ],
      columns3: [
        { prop: "name", label: "基站名称" },
        { prop: "model", label: "基站型号" },
        { prop: "image", label: "基站图片", slot: true },
        { prop: "address", label: "基站位置" },
        { prop: "state", label: "基站状态", slot: true }
      ],
      columns4: [
        { prop: "image", label: "无人机图片" , slot: true},
        { prop: "deviceName", label: "无人机名称" },
        { prop: "cateName", label: "无人机型号" },
        { prop: "state", label: "状态" ,slot: true},
        { prop: "store", label: "配发状态" , slot: true},
        { prop: "location", label: "所在地" },
        { prop: "anotherName", label: "所属机构" },
        { prop: "flyerName", label: "运维" },
      ],
      dataSource: null,
      saveDataSource: null
    };
  },
  methods: {
    ...mapActions("user",["Dbox"]),
    async switchList() {
      switch (this.active) {
        case 0:
          const res = await API.DEVICE.deviceListWeb(this.seach);
          this.dataSource = res.data.data;
        break;
        case 1:
          const { data } = await API.DEVICE.batteryList(this.seach)
          this.dataSource = data.data
          break;
        case 2:
          let mountRes = await API.DEVICE.ListMount(this.seach)
          this.dataSource = mountRes.data
        break;
        case 3: 
          const baseRes = await API.DEVICE.ListBaseStation();
          this.dataSource = baseRes.data.data
          break;
        default:
          break;
      }
    },
    activeChange(){
      //当用户选择其他类型就将数据清空
      if (this.activeSave!=this.active) {
        this.dataSource=[]
      }else{
        //当用户点击刚刚搜索过的类型，使用缓存的数据
      this.dataSource=this.saveDataSource
      }
    },
   async handle_change_music() {
      // this.seach.organId = null
     await this.switchList()
    //进入页面存储默认选择的类型:无人机搜索类型，为了解决用户在切换类型时还是会出现不同数据
    this.saveDataSource=this.dataSource
    },
    async init_chart() {
      await this.deviceStatusTotal()
      // this.newChart()
    },
   async getList() {
    //搜索时将用户点击的类型存储起来，然后用户点击去别的类型看不到数据，只能点击搜索才有数据
      this.activeSave=this.active
     await this.switchList()
    },
    changeState(val) {
      const obj = { 1: "正常", 2: "损坏", 3: "维修", 5: "保养" };
      return obj[val];
    },
    changeStore(val) {
      const obj = { 1: "出库", 2: "入库", 3: "暂无" };
      return obj[val];
    },
    async deviceStatusTotal() {
      const { data } = await API.DEVICE.deviceStatusTotal({type: this.type})
      data.map(item => {
        switch (item.status) {
          case 'repair':
          return item.status = '维修'
          break;
          case 'normal':
          return item.status = '正常'
          break;
          case 'damage':
          return item.status = '损坏'
          break;
          case 'care':
          return item.status = '保养'
          break;
          default : 
          break
        } 
      } )
      this.list = data
      
    },
    async typeChange() {
      await this.deviceStatusTotal()
      chart.changeData(this.list)
    },
    newChart() {
      chart = new Chart({
        container: "task-chart",
        height: 287,
        width: 900,
      });

      chart.data(this.list);
      chart.scale("count", {
        nice: true,
      });
      chart.tooltip({
        showMarkers: false,
        shared: true,
      });
      chart.axis('count', {  // 小数就不让它显示纵坐标了
        label: {
          formatter: (val) => {
            if(val%1 != 0) {
              return
            }
            return val;
          },
          
        },
      });

      chart
        .interval()
        .position("name*count")
        .color("status", ["#0D7AF5", "#00BC98", "#E99702", "#00B7E0"])
        .adjust([
          {
            type: "dodge",
            marginRatio: 0,
          },
        ]);

      chart.interaction("active-region");
      chart.legend({
        position: 'top-right',
        itemName: {
          style: {
            fill: '#ffffff',
          }
        },
      })

      chart.render();
    },
    changeState(val) {
      const obj = { 1: "正常", 2: "损坏", 3: "维修", 5: "保养" };
      return obj[val];
    },
   async change(){
     await this.getList()
    //  await this.switchList()
     //将用户点击后获取的数据存储起来
      this.saveDataSource=this.dataSource
    },
    async getAllOrganization() {
      // let { data } = await API.AIRWAY.getAllOrganizationBack();
      let data  = this.dbox_data
      this.selectList = this.getTreeData(data);
      // console.log(this.getTreeData(data));
      // console.log(this.selectList.length);
      this.seach.organId = this.selectList[this.selectList.length - 1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
  },
 async created() {
   await this.getAllOrganization()
   await this.handle_change_music()
   await this.init_chart()
   await this.Dbox()
    this.baseUrl = process.env.VUE_APP_WS_IP;
  },
};
</script>

<style lang="scss" scoped>
.top {
  // width: 1054px;
  width: 100%;
  height: 100%;
}
.handle {
  background: url(~@/assets/platform/device/header.png) no-repeat;
  width: 528px;
  height: 32px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-group {
  padding-top: 16px;
  display: flex;
  ::v-deep .el-select, ::v-deep .el-cascader {
    box-sizing: border-box;
    width: 176px;
    height: 36px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
    .el-input {
      box-sizing: border-box;
      .el-input__inner {
        height: 36px;
        line-height: 36px;
        background: #03183180;
        border: 1px solid #214884;
        box-sizing: border-box;
        border-radius: 0;
        color: #e0e6f2;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            line-height: 20px;
          }
          .el-select__caret {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.btn {
  width: 82px;
  height: 36px;
  opacity: 0.8;
  background-image: linear-gradient(
    180deg,
    rgba(44, 123, 255, 0.5) 0%,
    rgba(72, 140, 255, 0) 100%
  );
  border: 1px solid #488cff;
  font-size: 17px;
  color: #9fc4ff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
.flex {
  display: flex;
  justify-content: center;
}
.image {
  width: 80px;
  height: 40px;
}
</style>